.Toggle {
  cursor: pointer;
  vertical-align: middle;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex: 1 1;
      flex: 1 1;
  -ms-flex-align: center;
      align-items: center; }
  .Toggle__Input {
    display: none; }
  .Toggle__Control {
    border-radius: 45px;
    padding: 1px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    min-width: 50px;
    min-height: 25px;
    background-color: silver;
    margin-right: 4px; }
    .Toggle__Control.Toggle__Control--active {
      background-color: #D10303;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }
    .Toggle__Control__Control {
      display: block;
      border-radius: 90%;
      background-color: white;
      width: 25px;
      min-width: 25px;
      min-height: 25px; }
  .Toggle__Label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 1;
        flex: 1 1; }
