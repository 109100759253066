.Customer__Table__Toolbar .fa {
  font-size: 1.4em;
  width: 32px;
  text-align: center; }

.Customer__Table__Content__Row {
  cursor: pointer;
  padding: 2px;
  min-height: 32px; }
  .Customer__Table__Content__Row:nth-child(odd) {
    background-color: rgba(242, 237, 246, 0.5); }
  .Customer__Table__Content__Row:hover {
    background-color: rgba(225, 221, 229, 0.5); }
