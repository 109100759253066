.Modal__Title {
  background-color: #D10303;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 1.3em; }
  .Modal__Title > div {
    font-weight: bold; }
  .Modal__Title .fa {
    cursor: pointer; }

.Modal__Content {
  padding: 10px;
  padding-top: 0; }
