.SelectedCustomerContainer__CustomerHeader {
  padding: 4px;
  background-color: #f7f7f7;
  font-weight: bold;
  font-size: 1.2em; }
  .SelectedCustomerContainer__CustomerHeader .fa {
    cursor: pointer; }

.child-mr-2-omega > * {
  margin-right: 4px; }
  .child-mr-2-omega > *:last-child {
    margin-right: 0; }
