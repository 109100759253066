.font-small {
  font-size: 0.8em; }

.ProductBox {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .ProductBox__Title {
    color: #D10303;
    padding: 0 0.5em;
    font-size: 1.4em;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    text-align: center;
    display: block;
    width: 100%; }
  .ProductBox__Description {
    font-size: 0.9em; }
  .ProductBox__Price {
    font-weight: bold; }
  .ProductBox__Quantity input {
    margin-left: 4px;
    width: 60px;
    height: 26px; }
  .ProductBox__AddToCartButton {
    background-color: #D10303;
    color: white;
    font-size: 0.9em;
    outline: none;
    border-radius: .33333333em;
    border: 1px solid #181818;
    -webkit-box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.75);
            box-shadow: 0 0 0.5em 0 rgba(0, 0, 0, 0.75); }

@media (max-width: 413px) {
  .ProductBox {
    width: 100%;
    max-width: 100%;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -ms-flex-positive: 1;
        flex-grow: 1; } }

.red {
  color: red; }

.green {
  color: green; }

.omega-md-4z:nth-child(4) {
  margin-right: 0; }

.omega-2z {
  margin-right: 0; }
  .omega-2z:nth-child(2) {
    margin-right: 0; }
