.spinner {
  -webkit-animation: rotator 1.4s linear infinite;
          animation: rotator 1.4s linear infinite; }

@-webkit-keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

@keyframes rotator {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg); } }

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
      -ms-transform-origin: center;
          transform-origin: center;
  -webkit-animation: dash 1.4s ease-in-out infinite;
          animation: dash 1.4s ease-in-out infinite; }

.rainbow .path {
  -webkit-animation: colors 5.6s ease-in-out infinite;
          animation: colors 5.6s ease-in-out infinite; }

@-webkit-keyframes colors {
  0% {
    stroke: #4285F4; }
  25% {
    stroke: #DE3E35; }
  50% {
    stroke: #F7C223; }
  75% {
    stroke: #1B9A59; }
  100% {
    stroke: #4285F4; } }

@keyframes colors {
  0% {
    stroke: #4285F4; }
  25% {
    stroke: #DE3E35; }
  50% {
    stroke: #F7C223; }
  75% {
    stroke: #1B9A59; }
  100% {
    stroke: #4285F4; } }

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    -webkit-transform: rotate(450deg);
            transform: rotate(450deg); } }
