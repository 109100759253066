.DropDownMenu {
  position: relative;
  background-color: white;
  margin-right: 12px; }
  .DropDownMenu__ActiveItem {
    cursor: pointer; }
  .DropDownMenu__MenuItems {
    background-color: white;
    z-index: 99999;
    position: absolute;
    right: 0;
    top: -5px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; }
    .DropDownMenu__MenuItems.DropDownMenu__MenuItems--below {
      top: inherit; }
