.Avatar__Container {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  background-color: #D10303;
  color: white;
  text-shadow: none;
  text-decoration: none !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold; }
  .Avatar__Container img {
    border-radius: 50%;
    max-width: 100%;
    min-width: 100%; }

.Avatar__Title {
  text-decoration: none !important; }
  .Avatar__Title.Avatar__Title--hover {
    visibility: hidden; }

.Avatar:hover .Avatar__Title--hover {
  visibility: visible; }
